<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="binModal"
    :title="this.binEditing === true ? $t('Edit Bins') : $t('Add Bins')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="items-end grid sm:grid-cols-2 grid-cols-1 gap-x-4">
      <div>
        <vs-input v-validate="'required'" :label-placeholder="$t('Name') + '*'" :name="`name`" v-model="bin.name" :class="bin.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has(`name`)">{{ errors.first(`name`) }}</span>
      </div>
      <div class="mt-5">
        <v-select :class="bin.fulfillment_center ? 'input-customer-info-filled ' : ''" class="w-full input-customer-info product-dropdown" 
          autocomplete="nofill" label="name" :name="`fulfillment center`" v-model="bin.fulfillment_center" 
          :placeholder="$t('Fulfillment Center')" :options="fulfillmentCenters" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has(`fulfillment center`)">{{ errors.first(`fulfillment center`)}}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="binUpdate" v-if="binEditing === true" class="btn active-btn">{{ $t('Edit') }}</button>
      <button @click="addbin" v-else class="btn active-btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'

export default {
  props: ['binModal', 'binsEditData', 'binEditing'],
  components: {
    ShipbluPrompt,
    vSelect
  },
  data () {
    return {
      fulfillmentCenters: [],
      bin: {},
      fulfillmentCentersDic: {}
    }
  },
  watch : {
    'binModal' (val) {
      if (this.binEditing && val === true) {
        this.bin.name = this.binsEditData.name
        this.bin.fulfillment_center = this.fulfillmentCentersDic[this.binsEditData.fulfillment_center]
      }
    }
  },
  methods: {
    addbin () {
      this.$validator.resume()
      this.$validator.validateAll().then(result => {
        if (result) {
          const bins = {
            name: this.bin.name,
            fulfillment_center: this.bin.fulfillment_center.id,
            capacity: 1000000
          }
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/bins/', 'post', bins, true,
            () => {
              this.closeModal()
              this.$emit('loadBins')
            }
          )
        }
      })
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
          this.fulfillmentCenters.forEach(item => {
            this.fulfillmentCentersDic[item.id] = item.name  
          })
        }
      )
    },
    binUpdate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const bins = {
            name: this.bin.name,
            fulfillment_center: this.bin.fulfillment_center.id,
            capacity: 1000000
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/bins/${this.binsEditData.id}/`, 'patch', bins, true,
            () => {
              this.closeModal()
              this.$emit('loadBins')
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.bin = {}
      this.binEditing = false
      this.$emit('binModal', false)
    }
  },
  created () {
    this.loadFulfillmentCenters()
  }
}
</script>